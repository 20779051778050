import { getAuthToken } from "./../util/localStorage.util";
import axiosInstance from "../axios/axios";
import moment from "moment";

export const getDashboardNumber = async (channelId: any, productId: any, fiscalYearId: any): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`channel-admin/dashboard/reports?channelId=${channelId}&productId=${productId}&fiscalYearId=${fiscalYearId}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, false];
  } catch (error) {
    return [false, error];
  }
};

export const getAnnualReport = async (channelId: any, productId: any, fiscalYearId: any, filter: any): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`channel-admin/dashboard/enrollment-data?channelId=${channelId}&productId=${productId}&fiscalYearId=${fiscalYearId}&start_date=${
      filter?.dateRange.length>0?moment(filter?.dateRange[0]).format("YYYY-MM-DD"): '' || ""
    }&end_date=${filter?.dateRange.length>0?moment(filter?.dateRange[1]).format("YYYY-MM-DD"):'' || ""}`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, false];
  } catch (error) {
    return [false, error];
  }
};

export const getProductList = async (channelId: any): Promise<[any, any]> => {
 try {
   const response = await axiosInstance.get(`products?channelId=${channelId}`, {
     headers: {
       Authorization: `Bearer ${getAuthToken()}`,
     },
   });
   return [response, false];
 } catch (error) {
   return [false, error];
 }
};

export const getFiscalYearList = async (channelId: any): Promise<[any, any]> => {
  try {
    const response = await axiosInstance.get(`channel-admin/fiscal-year`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });
    return [response, false];
  } catch (error) {
    return [false, error];
  }
 };