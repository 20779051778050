import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React from "react";
import Sidenav from "./sidenav";
import HGI_LOGO from "../assets/images/hgi_logo.png";

interface IProps {
  hideDrawer: () => void;
  visible: boolean;
  onClose: () => void;
}

const navdrawer = ({ visible, hideDrawer, onClose }: IProps) => {
  return (
    <div>
      <Drawer
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
      >
        <div className="drawer__header nav-drawer">
          <div className="header__logo header-title">
            <img src={HGI_LOGO} alt="hgi_logo" width="250" />
          </div>
          <Button onClick={hideDrawer} className="drawer__trigger">
            <ArrowLeftOutlined />
          </Button>
        </div>
        <Sidenav sidebarColllapse={false} />
      </Drawer>
    </div>
  );
};

export default navdrawer;
